<template>
  <div id="app">
    <div>
      <h2>Valor do Dia:</h2>
      <money class="inp-text" v-bind="money" v-model="formattedInitialValue"></money> 
    </div>
    
    <div>
      <h2>Adicionar Produto</h2>
      <form @submit.prevent="addProduct">
      <label for="productName">Produto:</label>
      <input class="inp-text" type="text" id="productName" v-model="productName">
      <label for="productValue">Valor:</label>
      <money class="inp-text" v-bind="money" v-model="productValue"></money>
      <!-- <div>{{ formatCurrency(productValueMemory) }}</div> -->
      <button type="submit">Adicionar</button>
    </form>
    </div>
    
    <div v-if="products.length">
      <h2>Lista de Produtos</h2>
      <table>
        <thead>
          <tr>
            <th>Produto</th>
            <th>Valor</th>
            <th>Valor Dia</th>
            <th>Conversão</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(product, index) in reversedProducts" :key="index">
            <td>{{ product.name }}</td>
            <td>{{ formatCurrency(product.originalValue) }}</td>
            <td>{{ formatCurrency(product.valorDia) }}</td>
            <td>{{ formatCurrency(product.value) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    
    <button @click="resetValues" v-if="products.length">Resetar Valores</button>
  </div>
</template>

<script>
import {Money, VMoney} from 'v-money';
import numeral from 'numeral';

export default {
  components: {
    Money
  },
  directives: {money: VMoney},
  data() {
    return {
      initialValue: 4.98, // Valor inicial do dia
      productName: '',
      productValue: '',
      products: [],
      money: {
          decimal: '.',
          thousands: '',
          prefix: '',
          suffix: ' ',
          precision: 2,
          masked: false
        }
    };
  },
  computed: {
    formattedInitialValue: {
      get() {
        return this.initialValue;
      },
      set(value) {
        this.initialValue = value;
      }
    },
    reversedProducts() {
      return this.products.slice().reverse();
    },
    productValueMemory() {
      const originalValue = parseFloat(this.productValue).toFixed(2);
      return parseFloat(originalValue) * parseFloat(this.initialValue)
    }
  },
  methods: {
    addProduct() {
      if (this.productName && this.productValue) {
        const originalValue = parseFloat(this.productValue).toFixed(2);
    const product = {
      name: this.productName,
      value: parseFloat(originalValue) * parseFloat(this.initialValue),
      originalValue: parseFloat(originalValue),
      valorDia: parseFloat(this.initialValue)
    };
        this.products.push(product);
        this.productName = '';
        this.productValue = '';
      } else {
        alert("Por favor, preencha o nome e o valor do produto.");
      }
    },
    resetValues() {
      this.initialValue = 0;
      this.productName = '';
      this.productValue = '';
      this.products = [];
    },
    formatCurrency(value) {
      return numeral(value).format('0,0.00');
    }
  }
};
</script>

<style>
#app {
  font-family: Arial, sans-serif;
  max-width: 400px; /* Tamanho aproximado de um iPhone 12 Pro */
  margin: 50px auto;
  padding: 20px;
  border-radius: 20px;
  background-color: #f9f9f9; /* Cor de fundo minimalista */
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1); /* Sombra suave */
}

h2 {
  margin-top: 20px;
  margin-bottom: 10px;
}

.inp-text,
button {
  width: calc(100% - 10px);
  padding: 10px;
  margin-bottom: 10px;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1); /* Sombra suave */
}

.inp-text {
  background-color: #fff; /* Fundo branco para o campo de texto */
}

button {
  background-color: #007bff; /* Cor de destaque do botão */
  color: #fff; /* Cor do texto do botão */
  cursor: pointer;
}

button:hover {
  background-color: #0056b3; /* Cor de destaque do botão ao passar o mouse */
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  padding: 10px;
  border-bottom: 1px solid #ddd; /* Linha divisória entre os itens */
}

li:last-child {
  border-bottom: none; /* Remover linha divisória do último item */
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd; /* Linha divisória entre as células */
}

th {
  background-color: #007bff; /* Cor de destaque para os cabeçalhos */
  color: #fff; /* Cor do texto dos cabeçalhos */
}

tr:hover {
  background-color: #f5f5f5; /* Cor de destaque ao passar o mouse sobre a linha */
}
</style>
